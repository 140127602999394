import { BankIdLoginContextProvider } from '@organisms/BankIdLoginFlow/BankIdLoginContextProvider';
import BankIdActions from '@organisms/LoginModalWithBankId/BankIdActions/BankIdActions';
import { useState } from 'react';
import BankIdLoginModal from '@organisms/BankIdLoginFlow/BankIdLoginModal/BankIdLoginModal';
import { BANK_ID_DEVICES, NO_DEVICE } from '@constants/bankid';
import { trackBankIdLoginDevice } from '@helpers/analyticsHelpers/trackLogin';

export type BankIdLoginFlowProps = {
  closeParentModal: () => void;
  onLoginSuccess: () => void;
  handleCheckBoxChange: (checked: boolean) => void;
  rememberMe: boolean;
};

const BankIdLoginFlow = ({
  closeParentModal,
  onLoginSuccess,
  rememberMe,
  handleCheckBoxChange,
}: BankIdLoginFlowProps) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedDevice, setSelectedDevice] = useState<BANK_ID_DEVICES>(NO_DEVICE);

  const handleDeviceClick = (rememberMe: boolean, device: BANK_ID_DEVICES) => {
    setModalOpen(true);
    setSelectedDevice(device);
    trackBankIdLoginDevice(device);
  };

  const handleModalClose = (closeParent?: boolean) => {
    setModalOpen(false);
    setSelectedDevice(NO_DEVICE);

    if (closeParent) {
      closeParentModal();
    }
  };

  return (
    <>
      <BankIdLoginContextProvider>
        {!modalOpen && (
          <BankIdActions
            handleDeviceClick={handleDeviceClick}
            rememberMe={rememberMe}
            handleCheckBoxChange={handleCheckBoxChange}
          ></BankIdActions>
        )}

        {modalOpen && selectedDevice != NO_DEVICE && (
          <BankIdLoginModal
            closeModal={handleModalClose}
            selectedDevice={selectedDevice}
            onLoginSuccess={onLoginSuccess}
          />
        )}
      </BankIdLoginContextProvider>
    </>
  );
};

export default BankIdLoginFlow;

import {
  BANK_ID_AUTH_STATUSES,
  BANK_ID_HINT_CODES_ARRAY,
  BankIdAuthStatusType,
  BankIdHintCodeType,
} from '@constants/bankid';

const bankIdAuthStatusArr = Object.keys(BANK_ID_AUTH_STATUSES);

export const isStatusBankIdAuthStatus = (status: string): status is BankIdAuthStatusType =>
  bankIdAuthStatusArr.includes(status);

export const isHintCodeBankIdSignHintCode = (hintCode: string): hintCode is BankIdHintCodeType =>
  BANK_ID_HINT_CODES_ARRAY.includes(hintCode);

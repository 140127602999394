export const loginRegex = {
  b2c: /^(?=[0-9]*$)(?:.{12}|.{16})$/,
  b2b: /^\d{6}$/,
};

export const createAccountRegex = {
  b2c: /^(?=[0-9]*$)(?:.{12}|.{16})$/,
  b2b: {
    format: /^\d{6}-\d{4}$/,
    validOrg:
      /^((([25789])(\d{5})-(\d{4}))|(([0-9]{2})(([0])([1-9])|([1])([0-2]))(([0])([1-9])|([1-2])([0-9])|([3])([0-1]))-[0-9]{4}))$/,
  },
  general: {
    postalCode: /^[0-9]{5}$/,
  }
};

import pushGTMEvent from '@helpers/analyticsHelpers/pushGTMEvent';

export const trackLoginHintShown = () =>
  pushGTMEvent({
    event: 'Track',
    category: 'offers_page_login_modal',
    action: 'modal_shown',
    label: '',
    value: 0,
  });

export const trackLoginHintClosed = () =>
  pushGTMEvent({
    event: 'Track',
    category: 'offers_page_login_modal',
    action: 'modal_closed',
    label: '',
    value: 0,
  });

export const trackLoginHintAction = (action: 'continued_to_login' | 'continued_to_create_account') =>
  pushGTMEvent({
    event: 'Track',
    category: 'offers_page_login_modal',
    action,
    label: '',
    value: 0,
  });

import Personnummer from 'personnummer';

const formatSSN = (input: string) => {
  if (!input) return '';
  const replaceDashAndSpace = /-|\s/g;

  let formattedInput = input.replace(replaceDashAndSpace, '');
  if (formattedInput.length === 10 && Personnummer.valid(formattedInput)) {
    const dateOfBirth = `20${formattedInput.slice(0, 6)?.match(/.{2}/g)?.join('-')}`;

    if (new Date().getFullYear() - new Date(dateOfBirth).getFullYear() < 18) {
      formattedInput = `19${formattedInput}`;
    } else {
      formattedInput = `20${formattedInput}`;
    }
  }
  return formattedInput;
};

export default formatSSN;

import styled from 'styled-components';
import Checkbox from '@atoms/Checkbox/Checkbox';
import Button from '@atoms/Button/Button';
import { rem } from 'polished';
import Config from '@config';

export const StyledBankIdLogoWrapper = styled.div`
  flex-grow: 1;
  display: flex;

  ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
    flex-basis: ${rem(160)};
  }
`;

export const StyledCheckbox = styled(Checkbox)`
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: ${rem(4)};
  margin-top: ${rem(20)};
`;
export const StyledBankIdLoginForm = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
`;

export const StyledFullWidthButton = styled(Button)`
  width: 100%;
  margin-top: ${rem(16)};
`;

/* eslint-disable camelcase */
import axios, { AxiosResponse, CancelToken } from 'axios';

interface LoginParams {
  j_password: string;
  j_password_key: string;
  j_remember_me: boolean;
  j_username: string;
  j_username_key: string;
}

const login = (data: LoginParams, cancelToken: CancelToken): Promise<AxiosResponse<LoginResponseType>> => {
  const API_URL = `/login`;
  const headers = {
    'Content-Type': 'application/json',
  };

  return axios.post(API_URL, data, { cancelToken, headers });
};

export default login;

import styled, { css } from 'styled-components';
import { rem } from 'polished';
import variables from '@styles/variables';
import Config from '@config';

export const StyledLoginLink = styled.a<{ isLastElement?: boolean }>`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0 ${rem(20)};

  > * {
    height: 100%;
    align-items: center;
    display: flex;
  }

  ${(props) =>
    props.isLastElement &&
    css`
      border-left: ${rem(1)} solid ${variables.colors.lightGray};

      ${Config.MEDIA.FROM_DESKTOP} {
        margin-right: ${rem(-20)};
      }
    `}
`;

export const StyledLoginText = styled.span`
  font-family: ${variables.fonts.fontGothamMedium};
  font-size: ${rem(13)};
  margin-left: ${rem(10)};
  color: ${variables.colors.lighterBlack};
`;

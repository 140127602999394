import Button from '@atoms/Button/Button';
import styled from 'styled-components';
import { rem } from 'polished';

export const ButtonWrapper = styled.div`
  margin-top: ${rem(64)};
  width: 100%;
`;

export const StyledFullWidthButton = styled(Button)`
  width: 100%;
`;

export const PendingAuthWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: ${rem(40)};
  width: 100%;
`;

export const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: ${rem(32)} 0;
  max-width: ${rem(300)};
`;

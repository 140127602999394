const encrypt = async (value: string) => {
  const generateKey = async (salt: Uint8Array, passPhrase: string) => {
    const encoder = new TextEncoder();
    const keyMaterial = await crypto.subtle.importKey('raw', encoder.encode(passPhrase), { name: 'PBKDF2' }, false, [
      'deriveKey',
    ]);

    return crypto.subtle.deriveKey(
      {
        name: 'PBKDF2',
        salt: salt,
        iterations: 1000,
        hash: 'SHA-1',
      },
      keyMaterial,
      { name: 'AES-CBC', length: 128 },
      true,
      ['encrypt', 'decrypt']
    );
  };

  const cipher = async (salt: Uint8Array, iv: Uint8Array, passPhrase: string, plainText: string) => {
    const key = await generateKey(salt, passPhrase);
    const encoder = new TextEncoder();
    const encodedText = encoder.encode(plainText);

    const encryptedBuffer = await crypto.subtle.encrypt({ name: 'AES-CBC', iv: iv }, key, encodedText);

    return btoa(String.fromCharCode(...Array.from(new Uint8Array(encryptedBuffer))));
  };

  const mask = async (val: string) => {
    const key = `${Math.random()}`.substring(2, 10) + `${Math.random()}`.substring(2, 10);
    const iv = crypto.getRandomValues(new Uint8Array(16));
    const salt = crypto.getRandomValues(new Uint8Array(16));
    const cipherText = await cipher(salt, iv, key, val);
    const aesStr = `${Buffer.from(iv).toString('hex')}::${Buffer.from(salt).toString('hex')}::${cipherText}`;
    const str = btoa(aesStr);

    return { key, str };
  };

  return await mask(value);
};

export default encrypt;

import styled, { css } from 'styled-components';
import Config from '@config';
import { rem } from 'polished';
import Button from '@atoms/Button/Button';
import Form from '@molecules/Form/Form';

export const StyledContentWrapper = styled.div<{ gap: number }>`
  display: flex;
  flex-direction: column;
  height: 100%;

  ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
    padding: 0 ${rem(24)};
    flex: 1 1 auto;
    height: auto;
  }

  ${(props) =>
    props.gap &&
    css`
      gap: ${rem(props.gap)};
    `}
`;

export const StyledSubmitButton = styled(Button)`
  width: 100%;
`;

export const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-top: ${rem(10)};
  justify-content: space-between;
  height: 100%;
  flex: 1;

  ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
    gap: ${rem(40)};
  }
`;

export const StyledFooter = styled.div`
  margin-top: auto;
  display: flex;
  flex-direction: column;
  gap: ${rem(10)};

  ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
    margin-top: ${rem(4)};
  }
`;

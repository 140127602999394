import styled from 'styled-components';
import { rem } from 'polished';

export const StyledContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: ${rem(16)};
`;

import { createContext, ReactNode, useContext, useMemo, useState } from 'react';
import {
  BANK_ID_DEVICES,
  BankIdAuthStatusType,
  BankIdHintCodeType,
  NO_DEVICE,
  SELECT_SIGN_IN_METHOD,
  UNDEFINED,
} from '@constants/bankid';

type BankIdLoginContextType = {
  currentStatus: BankIdAuthStatusType;
  hintCode: BankIdHintCodeType;
  qrData: string;
  bankIdDevice: BANK_ID_DEVICES;
  rememberMe: boolean;
  setCurrentStatus: (status: BankIdAuthStatusType) => void;
  setHintCode: (hintCode: BankIdHintCodeType) => void;
  setQrData: (qrData: string) => void;
  setBankIdDevice: (bankIdDevice: BANK_ID_DEVICES) => void;
  setRememberMe: (rememberMe: boolean) => void;
};

const BankIdLoginContext = createContext<BankIdLoginContextType>({
  currentStatus: SELECT_SIGN_IN_METHOD,
  hintCode: UNDEFINED,
  qrData: '',
  bankIdDevice: NO_DEVICE,
  rememberMe: false,
  setCurrentStatus: () => {
    /* Overwritten by provider */
  },
  setHintCode: () => {
    /* Overwritten by provider */
  },
  setQrData: () => {
    /* Overwritten by provider */
  },
  setBankIdDevice: () => {
    /* Overwritten by provider */
  },
  setRememberMe: () => {
    /* Overwritten by provider */
  },
});

interface Props {
  children: ReactNode;
}

export const BankIdLoginContextProvider = ({ children }: Props) => {
  const [currentStatus, setCurrentStatus] = useState<BankIdAuthStatusType>(SELECT_SIGN_IN_METHOD);
  const [hintCode, setHintCode] = useState<BankIdHintCodeType>(UNDEFINED);
  const [qrData, setQrData] = useState<string>('');
  const [bankIdDevice, setBankIdDevice] = useState<BANK_ID_DEVICES>(NO_DEVICE);
  const [rememberMe, setRememberMe] = useState<boolean>(false);

  const contextValue = useMemo(
    () => ({
      currentStatus,
      setCurrentStatus,
      hintCode,
      setHintCode,
      qrData,
      setQrData,
      bankIdDevice,
      setBankIdDevice,
      rememberMe,
      setRememberMe,
    }),
    [currentStatus, hintCode, qrData, bankIdDevice, rememberMe]
  );

  return <BankIdLoginContext.Provider value={contextValue}>{children}</BankIdLoginContext.Provider>;
};

export const useBankIdLoginContext = () => useContext(BankIdLoginContext);

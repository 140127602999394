import { QRCodeSVG } from 'qrcode.react';
import React, { useEffect } from 'react';
import useTranslation from 'next-translate/useTranslation';
import { InfoContainer, QrContainer, Wrapper } from './BankIdQr.styles';
import { StartBankIdAuthArgs } from '@organisms/BankIdLoginFlow/bankIdTypes';
import Text from '@atoms/Text/Text';
import Button from '@atoms/Button/Button';
import { trackBankIdLoginCancelled } from '@helpers/analyticsHelpers/trackLogin';

export interface BankIdQrProps {
  startBankIdFlow: (args: StartBankIdAuthArgs) => void;
  handleCancel: () => void;
  showPending: boolean;
  showError: boolean;
  qrData: string;
}

/**
 * @description Note: manage mounts/unmounts properly for this component since unexpected unmounts/remounts will
 * cause the auth request flow to start over.
 */

const BankIdQr = ({ startBankIdFlow, handleCancel, showPending, showError, qrData }: BankIdQrProps) => {
  const { t } = useTranslation('login');

  useEffect(() => {
    startBankIdFlow({ mobile: true, sameDevice: false });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (showPending || showError) return null;

  return (
    <Wrapper data-testid="bankid-qr">
      <InfoContainer>
        <Text type="body">{t('login->bankId->startMobileAppQr')}</Text>
      </InfoContainer>
      <QrContainer>{qrData && <QRCodeSVG value={qrData} size={140} />}</QrContainer>

      <Button
        theme="link"
        size="small"
        onClick={() => {
          trackBankIdLoginCancelled('QR');
          handleCancel();
        }}
      >
        {t('login->bankId->cancel')}
      </Button>
    </Wrapper>
  );
};

export default BankIdQr;

import IconTooltip from '@components/molecules/IconTooltip/IconTooltip';
import { rem } from 'polished';
import styled, { css } from 'styled-components';

export const StyledInputTooltipContainer = styled.div`
  width: 100%;
  position: relative;
`;

export const StyledIconTooltip = styled(IconTooltip)<{ withLabel: boolean }>`
  position: absolute;
  top: ${rem(26)};
  transform: translateY(-50%);
  right: ${rem(15)};
  left: auto;
  margin-top: 0;
  ${(props) =>
    props.withLabel &&
    css`
      top: ${rem(50)};
    `}
`;

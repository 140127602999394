import styled from 'styled-components';
import variables from '@styles/variables';
import Config from '@config';
import { rem, rgba } from 'polished';
import Text from '@atoms/Text/Text';
import Button from '@atoms/Button/Button';
import Icon from '@atoms/Icon/Icon';

export const StyledWrapper = styled.div`
  position: absolute;
  width: ${rem(320)};
  padding: ${rem(24)};
  border-top: ${rem(1)} solid ${variables.colors.lightGray};
  box-shadow: 0 ${rem(8)} ${rem(28)} ${rem(0)} ${rgba(variables.colors.black, 0.5)};
  background-color: ${variables.colors.white};
  top: ${rem(variables.size.toolbarHeight + 11)};
  right: ${rem(10)};
  z-index: 100;
  max-width: ${rem(300)};

  &::before,
  &::after {
    bottom: 100%;
    right: ${rem(105)};
    border: solid transparent;
    content: '';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;

    ${Config.MEDIA.FROM_TABLET_LANDSCAPE} {
      right: ${rem(45)};
    }
  }

  &::before {
    border-bottom-color: ${variables.colors.lightGray};
    border-width: ${rem(11)};
    margin-right: ${rem(-11)};
  }

  &::after {
    border-bottom-color: ${variables.colors.white};
    border-width: ${rem(10)};
    margin-right: ${rem(-10)};
  }
`;

export const StyledContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

export const StyledHeading = styled(Text)`
  margin-bottom: ${rem(5)};
`;

export const StyledButtonGroup = styled.div`
  margin-top: ${rem(10)};
  display: flex;
  justify-content: space-between;
  gap: ${rem(20)};
`;

export const StyledButton = styled(Button)`
  flex-grow: 1;
  flex-basis: 0;
`;

export const StyledCloseIcon = styled(Icon)`
  position: absolute;
  cursor: pointer;
  top: ${rem(-4)};
  right: ${rem(-4)};
`;

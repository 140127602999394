import styled from 'styled-components';
import Config from '@config';
import { rem } from 'polished';

export const StyledContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rem(24)};
`;

export const StyledButtonWrapper = styled.div`
  display: flex;

  flex-direction: column;
  gap: ${rem(16)};

  ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
    flex-direction: row;
    gap: ${rem(20)};

    & > * {
      flex: 1;
    }
  }
`;

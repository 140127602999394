import styled, { css } from 'styled-components';
import { rem } from 'polished';
import Checkbox from '@atoms/Checkbox/Checkbox';

export const StyledContentWrapper = styled.div<{ gap: number; alignment?: string }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: ${(props) => props.alignment || 'left'};
  ${(props) =>
    props.alignment === 'center' &&
    css`
      align-items: center;
    `}

  ${(props) =>
    props.alignment === 'right' &&
    css`
      align-items: flex-end;
    `}

  &:last-child {
    margin-bottom: ${rem(7)};
  }

  ${(props) =>
    props.gap &&
    css`
      gap: ${rem(props.gap)};
    `}
`;

export const StyledCheckBox = styled(Checkbox)`
  margin-bottom: ${rem(4)};
`;

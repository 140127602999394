import styled from 'styled-components';
import { rem } from 'polished';

export const Wrapper = styled.div`
  margin-top: ${rem(40)};
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const QrContainer = styled.div`
  margin-top: ${rem(50)};
  margin-bottom: ${rem(80)};
`;

import { LoginApi, CheckCustomerForm, EncryptedTokenAssertionForm } from '@occ/api-client';

const loginApi = new LoginApi({ baseUrl: '' });

export const checkExistingCustomer = (form: CheckCustomerForm) => loginApi.checkCustomerExistsUsingPost(form);

export const checkExistingB2bCustomer = (organisationNumber: string) =>
  loginApi.checkExistingB2BCustomerUsingGet({ organisationNumber });

export const checkB2bCustomerBySapId = (sapid: string) => loginApi.checkExistingB2BCustomerBySapIdUsingGet({ sapid });

export const verifySmsToken = (form: EncryptedTokenAssertionForm) => loginApi.verifySmsTokenUsingPost(form);

export const validateEmailToken = (token: string) => loginApi.validateEmailTokenUsingPost({ token }, { format: 'json' });

export const changePassword = (
  token?: string,
  pwd?: string,
  checkPwd?: string,
  rememberMe?: boolean,
  pwdKey?: string,
  checkPwdKey?: string
) =>
  loginApi.changePasswordUsingPost({
    token,
    pwd,
    checkPwd,
    rememberMe,
    pwdKey,
    checkPwdKey,
  });

import Modal from '@organisms/Modal/Modal';
import { useEffect, useState } from 'react';
import useBankIdAuth from '@organisms/BankIdLoginFlow/useBankIdAuth';
import {
  BANK_ID_DEVICES,
  COMPLETE,
  CUSTOMERNOTFOUND,
  FAILED,
  LOGIN_OUTSTANDING_TRANSACTION,
  LOGIN_USER_CANCEL,
  NO_DEVICE,
  OTHER_DEVICE,
  SAME_DEVICE,
  SELECT_SIGN_IN_METHOD,
  UNDEFINED,
} from '@constants/bankid';
import useResponsive from '@hooks/useResponsive';
import UserAgent from '@helpers/useragent';
import BankIdQr from '@organisms/BankIdLoginFlow/BankIdLoginModal/BankIdQr/BankIdQr';
import { useBankIdLoginContext } from '@organisms/BankIdLoginFlow/BankIdLoginContextProvider';
import {
  CompleteAuth,
  FailedAuth,
  PendingAuth,
} from '@organisms/BankIdLoginFlow/BankIdLoginModal/AuthStatuses/AuthStatuses';
import Image from 'next/image';
import { StyledContentWrapper } from '@organisms/BankIdLoginFlow/BankIdLoginModal/BankIdLoginModal.styles';
import CustomerNotFoundModal from '@organisms/BankIdLoginFlow/CustomerNotFoundModal/CustomerNotFoundModal';

export type BankIdLoginModalProps = {
  selectedDevice: BANK_ID_DEVICES;
  closeModal: (closeParent?: boolean) => void;
  onLoginSuccess: () => void;
};

const BankIdLoginModal = ({ selectedDevice, closeModal, onLoginSuccess }: BankIdLoginModalProps) => {
  const [customerNotFoundSsn, setCustomerNotFoundSsn] = useState('');

  const { hintCode, currentStatus, bankIdDevice, setBankIdDevice, qrData } = useBankIdLoginContext();
  const { isCollectingLogin, startBankIdAuth, handleCancel, hasError } = useBankIdAuth({
    onLoginSuccess,
    onCustomerNotFound: (ssn) => setCustomerNotFoundSsn(ssn),
  });
  const { belowDesktop, isClient } = useResponsive();

  const isQr = bankIdDevice === OTHER_DEVICE;
  const showQrPending = isQr && hintCode !== LOGIN_OUTSTANDING_TRANSACTION;
  const showSameDevicePending = bankIdDevice === SAME_DEVICE && currentStatus !== SELECT_SIGN_IN_METHOD;
  const showError =
    hasError || currentStatus === FAILED || currentStatus === UNDEFINED || currentStatus === CUSTOMERNOTFOUND;
  const isCompleted = currentStatus === COMPLETE;
  const showPending = (showQrPending || showSameDevicePending) && !showError && !isCompleted;

  const handleCancelClick = (closeParent?: boolean) => {
    if (isQr) {
      setBankIdDevice(NO_DEVICE);
    }
    // If the user has cancelled the login request in app, let the handler know about that, so we won't send the cancel
    // request to BankId, because the order ref will be automatically cancelled by them.
    const userCancelledLoginInBankIdApp = hintCode === LOGIN_USER_CANCEL;
    handleCancel(userCancelledLoginInBankIdApp);

    // Type check for boolean to avoid passing event object as truthy value
    closeModal(closeParent === true);
  };

  useEffect(() => {
    setBankIdDevice(selectedDevice);
    // Start auth flow when modal is opened and selected device is same device
    // Wait for client to be ready
    if (selectedDevice === SAME_DEVICE && isClient) {
      startBankIdAuth({ mobile: belowDesktop, sameDevice: true, isIos: UserAgent.isIOS() });
    }

    return () => {
      // Cancel auth flow when modal is closed
      isCollectingLogin && handleCancelClick();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isClient]);

  return (
    <Modal size="medium" onClose={() => handleCancelClick(true)} hasCloseIcon={true}>
      <StyledContentWrapper>
        <Image
          unoptimized
          src="/icons/logos/BankID.svg"
          alt="BankID"
          width={90}
          height={86}
          style={{ height: 'auto' }}
        />

        {bankIdDevice === OTHER_DEVICE && !isCompleted && (
          <BankIdQr
            startBankIdFlow={startBankIdAuth}
            handleCancel={handleCancelClick}
            showPending={showPending}
            showError={showError}
            qrData={qrData}
          />
        )}

        {bankIdDevice && showPending && (
          <PendingAuth
            handleCancel={handleCancelClick}
            hintCode={hintCode}
            isSameDevice={bankIdDevice === SAME_DEVICE}
          />
        )}

        {bankIdDevice && isCompleted && <CompleteAuth />}

        {showError && <FailedAuth handleReturnClick={() => handleCancelClick(false)} hintCode={hintCode} isQr={isQr} />}

        {customerNotFoundSsn && (
          <CustomerNotFoundModal ssn={customerNotFoundSsn} handleCancel={() => closeModal()}></CustomerNotFoundModal>
        )}
      </StyledContentWrapper>
    </Modal>
  );
};

export default BankIdLoginModal;

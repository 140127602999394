import styled from 'styled-components';
import variables from '@styles/variables';
import { rem } from 'polished';
import Config from '@config';
import Checkbox from '@atoms/Checkbox/Checkbox';

export const StyledInputsWrapper = styled.div`
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: ${rem(12)};
  flex-grow: 1;
`;

export const StyledInputsItem = styled.div`
  width: 100%;
  margin-bottom: ${rem(18)};
`;

export const StyledBackendError = styled.div`
  margin-bottom: ${rem(15)};
  line-height: ${rem(11)};
`;

export const StyledErrorText = styled.div`
  color: ${variables.colors.primaryDark};
  font-size: ${rem(11)};
`;

export const StyledInputActionsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  white-space: nowrap;

  ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
    margin-bottom: ${rem(48)};
  }
`;

export const StyledFormBottom = styled.div`
  flex-direction: column;
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-grow: 1;

  button {
    margin: 0 0 ${rem(10)};
    width: 100%;

    &:last-child {
      margin: 0 0 0;
    }
  }

  ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
    margin: 0;

    button {
      margin: 0 ${rem(10)} ${rem(10)} 0;

      &:last-child {
        margin: 0;
      }
    }
  }
`;

export const StyledCheckbox = styled(Checkbox)`
  display: flex;
  width: 100%;
`;

import {
  BANK_ID_SIGN_HINT_CODES,
  BANK_ID_TRANSLATION_CODES,
  BankIdTranslationCodeType,
  BankIdHintCodeType,
  customerNotFound,
} from '@constants/bankid';

interface MapPendingTranslationKeyArgs {
  hintCode: BankIdHintCodeType;
  isSameDevice: boolean;
}
interface MapFailedTranslationKeyArgs {
  isQr: boolean;
  hintCode: BankIdHintCodeType;
}

const {
  LOGIN_ALREADY_IN_PROGRESS,
  LOGIN_CANCELLED,
  LOGIN_CERTIFICATE_ERROR,
  LOGIN_CUSTOMER_NOT_FOUND,
  LOGIN_EXPIRED_TRANSACTION,
  LOGIN_INTERNAL_ERROR,
  LOGIN_MAINTENANCE,
  LOGIN_NO_CLIENT,
  LOGIN_OUTSTANDING_TRANSACTION,
  LOGIN_REQUEST_TIMEOUT,
  LOGIN_START_FAILED,
  LOGIN_STARTED,
  LOGIN_USER_CANCEL,
  LOGIN_USER_SIGN,
} = BANK_ID_SIGN_HINT_CODES;

const { RFA1, RFA3, RFA4, RFA5, RFA6, RFA8, RFA9, RFA13, RFA15, RFA16, RFA17A, RFA17B, RFA21, RFA22 } =
  BANK_ID_TRANSLATION_CODES;

export const mapPendingTranslationKey = ({
  hintCode,
  isSameDevice,
}: MapPendingTranslationKeyArgs): BankIdTranslationCodeType => {
  switch (hintCode) {
    case LOGIN_OUTSTANDING_TRANSACTION:
    case LOGIN_NO_CLIENT:
      return isSameDevice ? RFA13 : RFA1;
    case LOGIN_USER_SIGN:
      return RFA9;
    case LOGIN_STARTED:
      return RFA15;
    default:
      return RFA21;
  }
};

export const mapFailedTranslationKey = ({ isQr, hintCode }: MapFailedTranslationKeyArgs): BankIdTranslationCodeType => {
  switch (hintCode) {
    case LOGIN_CANCELLED:
      return RFA3;
    case LOGIN_ALREADY_IN_PROGRESS:
      return RFA4;
    case LOGIN_INTERNAL_ERROR:
    case LOGIN_REQUEST_TIMEOUT:
    case LOGIN_MAINTENANCE:
      return RFA5;
    case LOGIN_USER_CANCEL:
      return RFA6;
    case LOGIN_EXPIRED_TRANSACTION:
      return RFA8;
    case LOGIN_CERTIFICATE_ERROR:
      return RFA16;
    case LOGIN_START_FAILED:
      return isQr ? RFA17B : RFA17A;
    case LOGIN_CUSTOMER_NOT_FOUND:
      return customerNotFound;
    default:
      return RFA22;
  }
};

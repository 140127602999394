import styled from 'styled-components';
import Config from '@config';
import { rem } from 'polished';
import Form from '@molecules/Form/Form';
import Text from '@atoms/Text/Text';
import Button from '@atoms/Button/Button';

export const StyledContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
    padding: 0 ${rem(24)};
    flex: 1 1 auto;
    height: auto;
  }
`;

export const StyledTabsWrapper = styled.div`
  margin-top: ${rem(15)};
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

export const StyledForm = styled(Form)`
  flex-grow: 1;
  display: flex;
`;

export const StyledFooter = styled.div`
  font-size: ${rem(13)};
  display: flex;
  flex-direction: column;
  margin-top: ${rem(16)};
`;

export const StyledFooterListItem = styled.li`
  margin-top: ${rem(8)};
`;

export const StyledFooterButton = styled(Button)`
  font-size: ${rem(13)};
`;

export const StyledFromMessage = styled(Text)`
  margin-bottom: ${rem(11)};
  padding-top: ${rem(7)};
`;

import React, { useEffect } from 'react';
import useTranslation from 'next-translate/useTranslation';
import { BankIdHintCodeType, LOGIN_CUSTOMER_NOT_FOUND, OTHER_DEVICE, SAME_DEVICE } from '@constants/bankid';
import { ButtonWrapper, PendingAuthWrapper, SpinnerWrapper, StyledFullWidthButton } from './AuthStatuses.styles';
import Text from '@atoms/Text/Text';
import Spinner from '@molecules/Spinner/Spinner';
import { mapFailedTranslationKey, mapPendingTranslationKey } from '@organisms/BankIdLoginFlow/translationHelper';
import { trackBankIdError, trackBankIdLoginCancelled } from '@helpers/analyticsHelpers/trackLogin';

export interface PendingAuthProps {
  handleCancel: () => void;
  hintCode: BankIdHintCodeType;
  isSameDevice: boolean;
}
export interface FailedAuthProps {
  hintCode: BankIdHintCodeType;
  handleReturnClick: () => void;
  isQr: boolean;
}

export const PendingAuth = ({ handleCancel, hintCode, isSameDevice }: PendingAuthProps) => {
  const { t } = useTranslation('bankid');
  const key = mapPendingTranslationKey({ hintCode, isSameDevice });

  return (
    <PendingAuthWrapper data-testid="pending-auth">
      <Text type="body">{t(`bankid:userMessage->${key}`)}</Text>
      <SpinnerWrapper>
        <Spinner size={32} />
      </SpinnerWrapper>
      <ButtonWrapper>
        <StyledFullWidthButton
          theme="link"
          size="small"
          onClick={() => {
            trackBankIdLoginCancelled('Pending Statuses');
            handleCancel();
          }}
        >
          {t('login:login->bankId->cancel')}
        </StyledFullWidthButton>
      </ButtonWrapper>
    </PendingAuthWrapper>
  );
};

export const FailedAuth = ({ handleReturnClick, hintCode, isQr }: FailedAuthProps) => {
  const { t } = useTranslation('bankid');
  const key = mapFailedTranslationKey({ isQr, hintCode });

  useEffect(() => {
    trackBankIdError(isQr ? OTHER_DEVICE : SAME_DEVICE, t(`bankid:userMessage->${key}`));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PendingAuthWrapper data-testid="failed-auth">
      <Text type="body">{t(`bankid:userMessage->${key}`)}</Text>
      <ButtonWrapper>
        <StyledFullWidthButton disabled={hintCode === LOGIN_CUSTOMER_NOT_FOUND} onClick={handleReturnClick}>
          {t('login:login->bankId->return')}
        </StyledFullWidthButton>
      </ButtonWrapper>
    </PendingAuthWrapper>
  );
};

export const CompleteAuth = () => {
  const { t } = useTranslation('login');

  return (
    <PendingAuthWrapper data-testid="pending-auth">
      <Text type="body">{t('login->bankId->loggingIn')}</Text>
      <SpinnerWrapper>
        <Spinner size={32} />
      </SpinnerWrapper>
    </PendingAuthWrapper>
  );
};

import pushGTMEvent from '@helpers/analyticsHelpers/pushGTMEvent';

export const trackCustomerNumber = (customerType: 'b2c' | 'b2b') =>
  pushGTMEvent({
    event: 'Track',
    category: `lost_password_modal${customerType === 'b2b' ? '_b2b' : ''}`,
    action: customerType === 'b2c' ? 'filled_in_social_security_number' : 'filled_in_customer_number',
    label: '',
    value: 0,
  });

export const trackRecoveryMethod = (customerType: 'b2c' | 'b2b', option: string) =>
  pushGTMEvent({
    event: 'Track',
    category: `lost_password_modal${customerType === 'b2b' ? '_b2b' : ''}`,
    action: `recovery_method_${option}`,
    label: '',
    value: 0,
  });

export const trackVerifyEmail = (customerType: 'b2c' | 'b2b') =>
  pushGTMEvent({
    event: 'Track',
    category: `lost_password_modal${customerType === 'b2b' ? '_b2b' : ''}`,
    action: 'verified_email_address',
    label: '',
    value: 0,
  });

export const trackVerifyPhone = (customerType: 'b2c' | 'b2b') =>
  pushGTMEvent({
    event: 'Track',
    category: `lost_password_modal${customerType === 'b2b' ? '_b2b' : ''}`,
    action: 'verified_phone_number',
    label: '',
    value: 0,
  });

export const trackVerifySmsCode = (customerType: 'b2c' | 'b2b') =>
  pushGTMEvent({
    event: 'Track',
    category: `lost_password_modal${customerType === 'b2b' ? '_b2b' : ''}`,
    action: 'verified_sms_code',
    label: '',
    value: 0,
  });

export const trackCreateNewPassword = (customerType: 'b2c' | 'b2b', keepMeLoggedIn: boolean) =>
  pushGTMEvent({
    event: 'Track',
    category: `lost_password_modal${customerType === 'b2b' ? '_b2b' : ''}`,
    action: 'created_new_password',
    label: `keep_me_logged_in_${keepMeLoggedIn ? 'activated' : 'deactivated'}`,
    value: 0,
  });

export const trackErrorMessage = (customerType: 'b2c' | 'b2b', errorMessage: string) =>
  pushGTMEvent({
    event: 'Track',
    category: `lost_password_modal${customerType === 'b2b' ? '_b2b' : ''}`,
    action: 'error_message_shown',
    label: errorMessage,
    value: 0,
  });

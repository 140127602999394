import {
  StyledBankIdLoginForm,
  StyledBankIdLogoWrapper,
  StyledCheckbox,
  StyledFullWidthButton,
} from './BankIdLoginForm.styles';
import useTranslation from 'next-translate/useTranslation';
import Image from 'next/image';
import { BANK_ID_DEVICES, OTHER_DEVICE, SAME_DEVICE } from '@constants/bankid';
import useResponsive from '@hooks/useResponsive';
import { useBankIdLoginContext } from '@organisms/BankIdLoginFlow/BankIdLoginContextProvider';
import { useEffect } from 'react';

export type BankIdLoginFormProps = {
  handleDeviceClick: (rememberMe: boolean, device: BANK_ID_DEVICES) => void;
  rememberMe: boolean;
  handleCheckBoxChange: (checked: boolean) => void;
};

const BankIdActions = ({
  handleDeviceClick,
  rememberMe: rememberMeParentState,
  handleCheckBoxChange,
}: BankIdLoginFormProps) => {
  const { t } = useTranslation('login');
  const { isMobile } = useResponsive();
  const { rememberMe, setRememberMe } = useBankIdLoginContext();

  useEffect(() => {
    // Bring parent state to context
    setRememberMe(rememberMeParentState);
  }, [rememberMeParentState, setRememberMe]);

  return (
    <StyledBankIdLoginForm>
      <StyledBankIdLogoWrapper>
        <Image
          unoptimized
          src="/icons/logos/BankID.svg"
          alt="BankID"
          width={90}
          height={86}
          style={{ height: 'auto' }}
        />
      </StyledBankIdLogoWrapper>

      <StyledCheckbox
        id="bankid-login-remember-me"
        checked={rememberMe}
        onChange={() => {
          setRememberMe(!rememberMe);
          handleCheckBoxChange(!rememberMe);
        }}
      >
        {t('login->rememberMe')}
      </StyledCheckbox>

      {isMobile ? (
        <>
          <StyledFullWidthButton onClick={() => handleDeviceClick(rememberMe, SAME_DEVICE)}>
            {t('login->bankId->startBankIdSameDeviceText')}
          </StyledFullWidthButton>

          <StyledFullWidthButton theme="secondary" onClick={() => handleDeviceClick(rememberMe, OTHER_DEVICE)}>
            {t('login->bankId->bankIdOnOtherDeviceText')}
          </StyledFullWidthButton>
        </>
      ) : (
        <StyledFullWidthButton onClick={() => handleDeviceClick(rememberMe, OTHER_DEVICE)}>
          {t('login->bankId->startDesktopFlowButtonText')}
        </StyledFullWidthButton>
      )}
    </StyledBankIdLoginForm>
  );
};

export default BankIdActions;

import { useEffect } from 'react';
import useTranslation from 'next-translate/useTranslation';
import {
  trackLoginHintShown,
  trackLoginHintClosed,
  trackLoginHintAction,
} from '@helpers/analyticsHelpers/trackLoginHint';
import OutsideClickWatcher from '@utility/OutsideClickWatcher/OutsideClickWatcher';
import Text from '@atoms/Text/Text';
import {
  StyledButton,
  StyledButtonGroup,
  StyledContent,
  StyledWrapper,
  StyledCloseIcon,
  StyledHeading,
} from './LoginHint.styles';
import CloseSmall from '@icons/close_small.svg';
interface LoginHintProps {
  onClickClose: () => void;
  onClickRegister: () => void;
  onClickLogin: () => void;
}

const LoginHint = ({ onClickLogin, onClickRegister, onClickClose }: LoginHintProps) => {
  useEffect(() => {
    trackLoginHintShown();
  }, []);

  const handleClose = () => {
    trackLoginHintClosed();
    onClickClose();
  };

  const handleRegister = () => {
    trackLoginHintAction('continued_to_create_account');
    onClickRegister();
  };

  const handleLogin = () => {
    trackLoginHintAction('continued_to_login');
    onClickLogin();
  };

  const { t } = useTranslation('loginHint');

  return (
    <OutsideClickWatcher clickHandler={handleClose}>
      <StyledWrapper>
        <StyledContent>
          <StyledHeading type="label">{t('loginHint->title')}</StyledHeading>
          <Text type="body" size="small">
            {t('loginHint->desc')}
          </Text>
          <StyledButtonGroup>
            <StyledButton size="small" type="button" theme="secondary" onClick={handleRegister}>
              {t('loginHint->createAccount')}
            </StyledButton>
            <StyledButton size="small" type="button" theme="primary" onClick={handleLogin}>
              {t('loginHint->loginIn')}
            </StyledButton>
          </StyledButtonGroup>
          <StyledCloseIcon svg={CloseSmall} size={12} onClick={handleClose} />
        </StyledContent>
      </StyledWrapper>
    </OutsideClickWatcher>
  );
};

export default LoginHint;

import styled from 'styled-components';
import Config from '@config';
import { rem } from 'polished';
import Button from '@atoms/Button/Button';
import Form from '@molecules/Form/Form';

export const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  gap: ${rem(24)};

  ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
    gap: ${rem(32)};
  }
`;

export const StyledSubmitButton = styled(Button)`
  width: 100%;

  ${Config.MEDIA.FROM_TABLET_PORTRAIT} {
    margin: 0 auto;
    width: ${rem(105)};
  }
`;

export const StyledFieldsWrapper = styled.div<{ gap: number }>`
  display: flex;
  flex-direction: column;
  gap: ${(props) => rem(props.gap)};
`;

export const StyledErrorList = styled.ul`
  padding-left: ${rem(14)};
  list-style: disc;
`;

import { useEffect, useRef, useState } from 'react';
import { isAxiosError } from 'axios';
import { isHintCodeBankIdSignHintCode, isStatusBankIdAuthStatus } from '@organisms/BankIdLoginFlow/bankIdTypeHelper';
import {
  COMPLETE,
  CUSTOMERNOTFOUND,
  FAILED,
  LOGIN_OUTSTANDING_TRANSACTION,
  LOGIN_START_FAILED,
  LOGIN_USER_SIGN,
  PENDING,
  SELECT_SIGN_IN_METHOD,
  UNDEFINED,
} from '@constants/bankid';
import { StartBankIdAuthArgs } from '@organisms/BankIdLoginFlow/bankIdTypes';
import { useBankIdLoginContext } from './BankIdLoginContextProvider';
import logger from '@logger';
import { bankIdAuth, bankIdCancel, bankIdLoginCollect, bankIdQrRefresh } from '@api/interfaces/checkoutApi';
import UserAgent from '@helpers/useragent';

const LOGIN_COLLECT_INTERVAL = 2000;
const REFRESH_QR_DATA_INTERVAL = 1000;
export const RESTORE_COLLECT_ON_FAIL_ATTEMPTS = 5;

/**
 * When auth with QR we make automatic retry attempts.
 * Control the number of retries here and take account for one initial request being sent, i.e the total will be MAX_QR_RETRIES + 1
 */
export const MAX_QR_RETRIES = 4;

interface UseBankIdAuthArgs {
  onLoginSuccess: () => void;
  onCustomerNotFound: (ssn: string) => void;
}

const useBankIdAuth = ({ onLoginSuccess, onCustomerNotFound }: UseBankIdAuthArgs) => {
  const [hasError, setHasError] = useState(false);

  const collectInterval = useRef<NodeJS.Timeout | null>(null);
  const refreshQrDataInterval = useRef<NodeJS.Timeout | null>(null);
  const pendingOrderRef = useRef('');
  const isCancelled = useRef(false);
  const isCollecting = useRef(false);
  const currentRetryAttempt = useRef(0);
  const restoreCollectOnFailAttempts = useRef(0);

  const { setCurrentStatus, setHintCode, setQrData, rememberMe } = useBankIdLoginContext();

  const clearRefreshQrDataInterval = () => {
    if (refreshQrDataInterval.current) {
      clearInterval(refreshQrDataInterval.current);
      refreshQrDataInterval.current = null;
    }
  };

  const clearIntervals = () => {
    isCollecting.current = false;
    if (collectInterval.current) {
      clearInterval(collectInterval.current);
      collectInterval.current = null;
    }
    clearRefreshQrDataInterval();
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => () => clearIntervals(), []); // clear intervals on unmount

  const shouldRetryAuth = () => {
    if (!currentRetryAttempt.current) return false;
    return currentRetryAttempt.current > 0 && currentRetryAttempt.current <= MAX_QR_RETRIES;
  };

  const attemptRestoreCollect = () => {
    clearIntervals();
    if (pendingOrderRef.current && !isCancelled.current) {
      collectInterval.current = setInterval(async () => {
        !isCollecting.current && (await handleBankIdCollect(pendingOrderRef.current));
      }, LOGIN_COLLECT_INTERVAL);
    }
  };

  const errorHandler = (error: unknown) => {
    clearIntervals();
    pendingOrderRef.current = '';
    currentRetryAttempt.current = 0;
    restoreCollectOnFailAttempts.current = 0;
    const errorStatus = isAxiosError(error) && error.response?.data?.errorStatus;
    if (errorStatus && isStatusBankIdAuthStatus(errorStatus)) {
      setCurrentStatus(errorStatus);
    } else {
      setCurrentStatus(UNDEFINED);
    }
    setHasError(true);
  };

  const statusHandler = ({ status, hintCode, ssn }: { status?: string; hintCode?: string; ssn?: string }) => {
    if (status === FAILED) {
      clearIntervals();
    } else if (status === COMPLETE) {
      pendingOrderRef.current = '';
      clearIntervals();
      setCurrentStatus(COMPLETE);
      onLoginSuccess();
    } else if (status === CUSTOMERNOTFOUND) {
      clearIntervals();
      onCustomerNotFound(ssn || '');
    } else if (hintCode === LOGIN_USER_SIGN && refreshQrDataInterval.current) {
      clearRefreshQrDataInterval();
    }
    if (!status || !hintCode) return;
    setCurrentStatus(isStatusBankIdAuthStatus(status) ? status : UNDEFINED);
    setHintCode(isHintCodeBankIdSignHintCode(hintCode) ? hintCode : UNDEFINED);
  };

  const handleBankIdCollect = async (orderRef: string) => {
    try {
      isCollecting.current = true;
      const bankIdCollectRes = await bankIdLoginCollect(orderRef, rememberMe);
      const { status, hintCode, ssn } = bankIdCollectRes.data;

      if (!isCancelled.current) {
        // check the hintCode and the current count of attempts to see if we should initiate another retry
        if (status === FAILED && hintCode === LOGIN_START_FAILED && shouldRetryAuth()) {
          clearIntervals();
          // initiate retry of auth with new orderRef & associated QrData
          startBankIdAuth({ mobile: true, sameDevice: false });
          return; // ..to not set new values into redux causing interface to update.
        }
        // else act on other statuses & hint codes & update redux state
        statusHandler({ status, hintCode, ssn });
      }
    } catch (error) {
      // check to see if we should bypass errorHandler and make restore attempt
      const errorStatus = isAxiosError(error) && error.response?.data?.errorStatus;
      const isUnknownErrorStatus = !errorStatus && !isStatusBankIdAuthStatus(errorStatus);

      if (isUnknownErrorStatus && restoreCollectOnFailAttempts.current > 0) {
        restoreCollectOnFailAttempts.current -= 1;
        attemptRestoreCollect();
        return;
      }
      errorHandler(error);
    } finally {
      isCollecting.current = false;
    }
  };

  const refreshQrData = async () => {
    try {
      const qrResponse = await bankIdQrRefresh({
        validateStatus: (status) => {
          // Do not trigger catch for status 410 (status recieved for expired qr and handled elsewhere).
          return (status >= 200 && status < 300) || status === 410;
        },
      });
      if (qrResponse.data) {
        setQrData(qrResponse.data);
      }
    } catch (ex) {
      errorHandler(ex);
    }
  };

  const startBankIdAuth = async ({ mobile, sameDevice, isIos = false }: StartBankIdAuthArgs) => {
    isCancelled.current = false;
    try {
      setCurrentStatus(PENDING);
      setHintCode(LOGIN_OUTSTANDING_TRANSACTION);

      const shouldGenerateQr = !sameDevice;
      // initiate signing
      const response = await bankIdAuth({ mobile, generateQrData: shouldGenerateQr });
      const { orderRef, autoStartToken } = response.data;

      if (sameDevice && autoStartToken) {
        // iOS is eager to kill the network connection while mobile browser is in background. Store a number of attempts to retry the collect polling here
        restoreCollectOnFailAttempts.current = isIos ? RESTORE_COLLECT_ON_FAIL_ATTEMPTS : 0;
        if (isIos) {
          // Safari requires a hash in the URL to prevent reloading page on redirect
          const location = UserAgent.isSafariBrowser() ? `${window.location.href}#bankid` : window.location.origin;
          window.location.href = `https://app.bankid.com/?autostarttoken=${autoStartToken}&redirect=${location}`;
        } else {
          window.location.href = `bankid:///?autostarttoken=${autoStartToken}&redirect=null`;
        }
      }

      if (orderRef && !isCancelled.current) {
        pendingOrderRef.current = orderRef;
        // start long polling to collect status and await completion
        collectInterval.current = setInterval(async () => {
          !isCollecting.current && (await handleBankIdCollect(orderRef));
        }, LOGIN_COLLECT_INTERVAL);
      }
      if (shouldGenerateQr && !isCancelled.current) {
        currentRetryAttempt.current += 1; // when auth with QR: store attempt count to keep track of auto retry
        refreshQrDataInterval.current = setInterval(async () => {
          await refreshQrData();
        }, REFRESH_QR_DATA_INTERVAL);
        await refreshQrData();
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  const handleBankIdCancel = async () => {
    try {
      await bankIdCancel(pendingOrderRef.current);
      pendingOrderRef.current = '';
    } catch (e) {
      if (isAxiosError(e)) {
        logger.error({ error: 'Failed BankId cancel', message: e.message, responseData: e.response?.data });
      }
    }
  };

  const handleCancel = (userCancelledInApp?: boolean) => {
    isCancelled.current = true;
    isCollecting.current = false;
    if (pendingOrderRef.current && !userCancelledInApp) {
      handleBankIdCancel();
    } else {
      pendingOrderRef.current = '';
    }
    currentRetryAttempt.current = 0;
    restoreCollectOnFailAttempts.current = 0;
    setHasError(false);
    clearIntervals();

    setCurrentStatus(SELECT_SIGN_IN_METHOD);
    setHintCode(LOGIN_OUTSTANDING_TRANSACTION);
    setQrData('');
  };

  const handleRetry = () => {
    setHasError(false);
    pendingOrderRef.current = '';
    currentRetryAttempt.current = 0;
    restoreCollectOnFailAttempts.current = 0;

    setCurrentStatus(SELECT_SIGN_IN_METHOD);
    setHintCode(LOGIN_OUTSTANDING_TRANSACTION);
    setQrData('');
  };

  return {
    isCollectingLogin: !!collectInterval.current,
    startBankIdAuth,
    handleRetry,
    handleCancel,
    hasError,
  };
};

export default useBankIdAuth;

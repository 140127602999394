import Heading from '@atoms/Heading/Heading';
import Text from '@atoms/Text/Text';
import Button from '@atoms/Button/Button';
import {
  StyledButtonWrapper,
  StyledContentWrapper,
} from '@organisms/BankIdLoginFlow/CustomerNotFoundModal/CustomerNotFoundModal.styles';
import Modal from '@organisms/Modal/Modal';
import paths from '@constants/paths';
import useCustomRouter from '@hooks/useCustomRouter';
import useTranslation from 'next-translate/useTranslation';
import Personnummer from 'personnummer';
import { useMemo } from 'react';

interface CustomerNotFoundModalProps {
  ssn: string;
  handleCancel: () => void;
}

const CustomerNotFoundModal = ({ ssn, handleCancel }: CustomerNotFoundModalProps) => {
  const router = useCustomRouter();
  const { t } = useTranslation('login');

  const isUnder18 = useMemo(() => new Personnummer(ssn).getAge() < 18, [ssn]);

  const handleContinue = () => {
    (async () => {
      router.push(
        {
          pathname: paths.REGISTER_PRIVATE_CREATE,
          query: { ...router.query, ssn },
        },
        { pathname: paths.REGISTER_PRIVATE_CREATE }
      );
    })();
  };

  return (
    <Modal size="small" hasCloseIcon={false}>
      <StyledContentWrapper>
        <Heading variant="h3">{t('login->bankId->noCustomerFound->heading')}</Heading>

        <Text type="body">
          {t(isUnder18 ? 'login->bankId->noCustomerFound->bodyUnder18' : 'login->bankId->noCustomerFound->body')}
        </Text>

        {isUnder18 ? (
          <Button theme="primary" onClick={handleCancel}>
            {t('login->bankId->noCustomerFound->okBtn')}
          </Button>
        ) : (
          <StyledButtonWrapper>
            <Button theme="primary" onClick={handleContinue}>
              {t('login->bankId->noCustomerFound->createAccountBtn')}
            </Button>

            <Button theme="secondary" onClick={handleCancel}>
              {t('login->bankId->noCustomerFound->cancelBtn')}
            </Button>
          </StyledButtonWrapper>
        )}
      </StyledContentWrapper>
    </Modal>
  );
};

export default CustomerNotFoundModal;

import { CheckoutApi } from '@api/generated/storefront/storefront/checkout-api';
import { CancelToken } from 'axios';
import type { AxfoodCartEntryStatusViewModel, BankIdForm } from '@occ/api-client';
import { AxiosRequestConfig, AxiosResponse } from 'axios/index';
import { BANK_ID_SIGN_STATUS } from '@constants/bankid';

const checkoutApi = new CheckoutApi(undefined, '');

interface PlaceOrderProps {
  saveCard?: boolean;
  selectedCardId: string;
  orderRef?: string;
  klarnaAuthorizationToken?: string;
  userClickedContinue: boolean;
  cancelToken?: CancelToken;
}
export const placeOrder = ({
  saveCard,
  selectedCardId,
  orderRef,
  userClickedContinue,
  cancelToken,
  klarnaAuthorizationToken,
}: PlaceOrderProps) => {
  return checkoutApi.simplifiedPlaceOrderUsingPOST(
    saveCard,
    selectedCardId,
    orderRef,
    klarnaAuthorizationToken,
    userClickedContinue,
    {
      cancelToken,
      // Make sure 402 is interpreted as valid (expected response for PayEx redirects)
      validateStatus: (status) => {
        return (status >= 200 && status < 300) || status === 402;
      },
    }
  );
};

export const initCheckout = async () => {
  const response = await checkoutApi.initializeCheckoutUsingGET();
  return response.data as unknown as {
    emptyCart: boolean;
    reason?: string;
    cartStatus?: AxfoodCartEntryStatusViewModel[];
  };
};

// TODO: This method should be used when implementing the new BankID signing in the checkout
export const bankIdSign = (data: BankIdForm, options?: AxiosRequestConfig) => {
  return checkoutApi.bankIdSignUsingPOST(data, options);
};

// TODO: This method should be used when implementing the new BankID signing in the checkout
export const bankIdCollect = (orderRef: string, options?: AxiosRequestConfig) => {
  return checkoutApi.bankIdCollectUsingPOST({ orderRef }, options) as Promise<
    AxiosResponse<{ hintCode: string; ssn: string; status: BANK_ID_SIGN_STATUS }>
  >;
};

export const bankIdAuth = (data: BankIdForm, options?: AxiosRequestConfig) => {
  return checkoutApi.bankIdAuthUsingPOST(data, options);
};

export const bankIdLoginCollect = (orderRef: string, rememberMe: boolean, options?: AxiosRequestConfig) => {
  return checkoutApi.bankIdCollectLoginUsingPOST({ orderRef, rememberMe: rememberMe.toString() }, options);
};

export const bankIdQrRefresh = (options?: AxiosRequestConfig) => {
  return checkoutApi.bankIdQrDataUsingPOST(options);
};
export const bankIdCancel = (orderRef: string, options?: AxiosRequestConfig) =>
  checkoutApi.bankCancelUsingPOST({ orderRef }, options);

export const createKlarnaSession = (options?: AxiosRequestConfig) => {
  return checkoutApi.createSessionUsingGET(options);
};
